const { axiosCreate } = require('./Api')
const { getCart } = require('./CartService')
import store from '../state/store'
import router from '../router'

async function login({ username, password }) {
  const result = await axiosCreate.get('public/registerUser', {
    auth: {
      username: username,
      password: password
    }
  })

  if (result.data.UserName) {
    const accounts = result.data.Accounts.Collection
    let activeAcountsCount = 0
    let activeAcounts = []

    for (let account of accounts) {
      if (account.IsCustomerActive === false) continue
      activeAcountsCount++
      activeAcounts.push(account)
    }

    if (activeAcountsCount < 1)
      return 'Je account is geactiveerd maar uw organisatie is nog niet bevestigd, dit proces kan enkele dagen duren.'

    store.commit('setBearerToken', {
      token: `Bearer ${result.data.Token.Acces_token}`
    })

    await store.commit('setUser', { user: result.data })
  }

  if (result.data.Message) {
    if (result.data.Message == 'IncorrectDetails') {
      return 'De ingevulde email/wachtwoord combinatie is onjuist.'
    } else if (result.data.Message == 'UserNotApproved') {
      return 'Je emailadres is nog niet bevestigd, controleer uw email en bevestig.'
    } else if (result.data.Message == 'UserLockedOut') {
      return 'Je account is geblokkeerd, neem contact op met de uitleendienst.'
    } else {
      return 'Je account is nog niet geactiveerd'
    }
  }

  if (result.data === '') {
    return 'Er is geen actieve organisatie waar uw account aan is gekoppeld. Dit kan zijn omdat uw organisatie nog geaccepteerd moet worden.'
  }

  await getCart()
  if (
    result.data.Accounts.Collection &&
    result.data.Accounts.Collection.length > 1
  ) {
    router.push({ name: 'profileSelector' })
  } else {
    router.push({ name: 'index', query: { loggedIn: true } })
  }
}

async function register({
  username,
  email,
  password,
  street,
  houseNumber,
  postcode,
  city,
  tel,
  companyName,
  website,
  customerOrganisationNumber,
  targetAudience,
  firstname,
  lastname,
  CUST_MobilePhone,
  CONTACT_email,
  CONTACT_street,
  CONTACT_houseNumber,
  CONTACT_postcode,
  CONTACT_city,
  CONTACT_tel,
  CONTACT_MobilePhone,
  CUST_SocialMedia,
  CUST_Notes,
  InvoiceCompanyName,
  InvoiceStreet,
  InvoiceHouseNumber,
  InvoiceHouseNumberAddition,
  InvoiceZipCode,
  InvoiceCity,
  InvoiceTel,
  InvoiceEmail,
  InvoiceFirstName,
  InvoiceLastName,
  VATNo,
  CUST_Comments,
  CUST_ReceiveNewsletter
} = {}) {
  const result = await axiosCreate.post('public/registerUser', {
    UserName: username,
    Email: email,
    Password: password,
    ConfirmPassword: password,
    FirstName: firstname,
    LastName: lastname,
    Tel: tel,
    ECCUST_EnterpriseNumber: customerOrganisationNumber,
    CompanyName: companyName,
    Street: street,
    ReceiverName: `${firstname} ${lastname}`,
    HouseNumber: houseNumber,
    ZipCode: postcode,
    City: city,
    CUST_MobilePhone,
    UserEmail: CONTACT_email,
    CONTACT_Email: CONTACT_email,
    CONTACT_Tel: CONTACT_tel,
    CONTACT_Street: CONTACT_street,
    CONTACT_HouseNumber: CONTACT_houseNumber,
    CONTACT_ZipCode: CONTACT_postcode,
    CONTACT_City: CONTACT_city,
    CONTACT_MobilePhone,
    LanguageID: 'NL',
    Website: website,
    ConfirmURL: `${process.env.VUE_APP_FRONTEND_URL}/account-aangemaakt/`,
    Salutation: '',
    CUST_TargetAudience: targetAudience,
    CUST_SocialMedia,
    CUST_Notes,
    InvoiceCompanyName,
    InvoiceStreet,
    InvoiceHouseNumber,
    InvoiceHouseNumberAddition,
    InvoiceZipCode,
    InvoiceCity,
    InvoiceTel,
    InvoiceEmail,
    InvoiceFirstName,
    InvoiceLastName,
    VATNo,
    CUST_Comments,
    CUST_ReceiveNewsletter
  })
  return result
}

async function confirmUser({ code }) {
  const result = await axiosCreate.post(
    'public/registerUser/confirmuser',
    null,
    {
      params: {
        code
      }
    }
  )
  return result
}

async function getSubs() {
  const result = await axiosCreate.get(
    `public/registerUser/${store.getters.contactID}/subaccounts`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  store.commit('setSubUsers', { subUsers: result.data.Collection })
  return result.data.Collection
}

async function newSub({
  email,
  password,
  confirmPassword,
  street,
  city,
  houseNumber,
  postcode,
  tel,
  firstname,
  lastname,
  CONTACT_MobilePhone
}) {
  const result = await axiosCreate.post(
    `public/registerUser/${store.getters.contactID}/subaccounts`,
    {
      UserEmail: email,
      UserName: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      Salutation: '',
      CUST_Street: street,
      CUST_HouseNumber: houseNumber,
      ConfirmURL: `${process.env.VUE_APP_FRONTEND_URL}/account-aangemaakt/`,
      CUST_ZipCode: postcode,
      Tel: tel,
      CUST_City: city,
      CONTACT_MobilePhone
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result
}

async function requestPasswordReset({ email }) {
  const result = await axiosCreate.post('public/registerUser/lostpassword', {
    Email: email,
    LanguageID: 'NL',
    URL: `${process.env.VUE_APP_FRONTEND_URL}/wachtwoord-herstellen?code=<code>`
  })
  return result
}

async function setNewPassword({ code, password, confirmPassword }) {
  const result = axiosCreate.post('public/registerUser/resetpassword', {
    Code: code,
    Password: password,
    ConfirmPassword: confirmPassword
  })
  return result
}

async function modifySub({
  email,
  password,
  confirmPassword,
  street,
  city,
  houseNumber,
  postcode,
  tel,
  firstname,
  lastname,
  contactID,
  userName,
  CONTACT_MobilePhone,
  MobilePhone,
  CUST_ReceiveNewsletter
}) {
  const result = await axiosCreate.patch(
    `public/registerUser/${store.getters.contactID}/subaccounts/${userName}/${contactID}`,
    {
      ContactID: contactID,
      UserEmail: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      CUST_Street: street,
      CUST_HouseNumber: houseNumber,
      CUST_ZipCode: postcode,
      Tel: tel,
      CUST_City: city,
      CONTACT_MobilePhone,
      MobilePhone,
      CUST_ReceiveNewsletter
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result.data
}

async function deleteSub({ subUserName, subUserContactID }) {
  const result = await axiosCreate.delete(
    `public/registerUser/${store.getters.contactID}/subaccounts/${subUserName}/${subUserContactID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result
}

async function updateCustomer({
  Email,
  CustomerStreet,
  CustomerEmail,
  ZipCode,
  CustomerDescription,
  HouseNumber,
  HouseNumberAddition,
  ECCUST_EnterpriseNumber,
  City,
  CompanyName,
  Website,
  CUST_SocialMedia
}) {
  const result = await axiosCreate.patch(
    `customers/${store.getters.customerID}`,
    {
      CustomerID: store.getters.customerID,
      Email,
      Street: CustomerStreet,
      ZipCode,
      HouseNumber,
      HouseNumberAddition,
      ECCUST_EnterpriseNumber,
      City,
      Website,
      CompanyName,
      CUST_Notes: CustomerDescription,
      CUST_SocialMedia,
      CustomerEmail
    },
    {
      headers: { authorization: store.getters.token }
    }
  )

  store.commit('updateUser', {
    user: {
      Email,
      CustomerStreet,
      ZipCode,
      HouseNumber,
      HouseNumberAddition,
      ECCUST_EnterpriseNumber,
      City,
      Website,
      CompanyName,
      CUST_Notes: CustomerDescription,
      CUST_SocialMedia: CUST_SocialMedia
    }
  })

  store.commit('updateAccount', {
    user: {
      Email,
      CustomerStreet,
      ZipCode,
      HouseNumber,
      HouseNumberAddition,
      ECCUST_EnterpriseNumber,
      City,
      Website,
      CompanyName,
      CUST_Notes: CustomerDescription,
      CUST_SocialMedia: CUST_SocialMedia
    }
  })

  return result
}

async function transferOwnership({ subUserName, subUserContactID }) {
  const result = await axiosCreate.post(
    `public/custregisterUser/${store.getters.contactID}/subAccounts/${subUserName}/${subUserContactID}/promote`,
    {},
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result
}

module.exports = {
  login,
  register,
  getSubs,
  newSub,
  modifySub,
  updateCustomer,
  deleteSub,
  requestPasswordReset,
  setNewPassword,
  confirmUser,
  transferOwnership
}
